<template>
    <div class="reverse-spinner"></div>
</template>
<script>
export default {
    name: 'Spinner',
}
</script>

<style scoped>
    .reverse-spinner {
        position: relative;
        height: 100px;
        width: 100px;
        border: 4px solid transparent;
        border-top-color: #4cbef1;
        border-left-color: #4cbef1;
        border-radius: 50%;
        -webkit-animation: spin 1.5s linear infinite;
        animation: spin 1.5s linear infinite;
    }

    .reverse-spinner::before {
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        content: "";
        border: 4px solid transparent;
        border-top-color: #F2A31D;
        border-left-color: #F2A31D;
        border-radius: 50%;
        -webkit-animation: spinBack 1s linear infinite;
        animation: spinBack 1s linear infinite;
    }
    @keyframes spin {
        from {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    
    }
    @keyframes spinBack {
        from {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(-720deg);
            transform: rotate(-720deg);
        }
    }
</style>
